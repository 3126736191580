import React from 'react';
import Header from '../../components/Header/Header.jsx';
import AccomplishedProjectDetails from '../../components/AccomplishedProjectDetails/AccomplishedProjectDetails';

function ProjectDetails() {
  return (
    <div>
      <Header />
      <AccomplishedProjectDetails />
    </div>
  );
}

export default ProjectDetails;